<template lang="pug">
FPhoneInput(
  :country-code="countryCode"
  v-bind="props"
  @update:country-code="handleCountryCodeUpdate"
)
</template>

<script setup lang="ts">
import type { FPhoneInputProps } from '@fifteen/design-system-vue';

export type FcwPhoneInputProps = Omit<FPhoneInputProps, 'countryCode'>;

const props = defineProps<FcwPhoneInputProps>();

const { clientState } = storeToRefs(useClientStore());

const emit = defineEmits<{
  (name: 'country-code-change', countryCode?: CountryCode): void;
}>();

const countryCode = computed(() => clientState.value.countryCode);

function handleCountryCodeUpdate(countryCode: CountryCode): void {
  emit('country-code-change', countryCode);
}
</script>
